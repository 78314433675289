.positionWrapper {
  display: grid;
  grid-template: "position company";
  grid-template-columns: 1fr 300px;
}

.position {
  grid-area: position;
  padding: 10px;
}

.company {
  grid-area: company;
  padding:10px;
  background: #f0f0f0;
}

.vertical-timeline::before {
  background: #f0f0f0 !important;
}

.vertical-timeline-element-icon {
  box-shadow: 0 0 0 4px #f0f0f0, inset 0 2px 0 rgba(0,0,0,.08), 0 3px 0 4px rgba(0,0,0,.05);
}

.titleArea {
  display: grid;
  grid-template: "title actions";
  grid-template-columns: 1fr 1fr;
}

.title {
  grid-area: title;
}

.actions {
  grid-area: actions;
  text-align: right;
}

.actions svg {
  width: 20px;
}

.addTimeline {
  margin-bottom: 20px;
}

.half {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.google-places-autocomplete__input {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  autocomplete: off;
}
