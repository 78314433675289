.messagesWrapper {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;

  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-areas:
  "list chat"
}

.list {
  grid-area: list;
  width: 98%;
}

.chat {
  grid-area: chat;
  width: 100%;
}


.chatGroupSegments {
  width: 100%;
  color: white;

  display: grid !important;
  grid-template-columns: 50px  1fr;
  grid-template-areas:
  "lgLogo lgContent"
}

.chatGroupSegments .list-group-item {
  display: grid !important;
}

.chatLogo {
  grid-area: lgLogo;
  width: 100px;
  height: 40px;

  color: white;

  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.chatContent {
  grid-area: lgContent;
  color: black;
}


.chatContainer {
  height: 60vh;
  width: 100%;
}

.message {
  width: 100%;
  color: black;

  display: grid;
  margin-top: 10px;
}

.css-y1c0xs {
  overflow-x: hidden;
}

.received {
  grid-template-columns: 50px  280px;
  grid-template-areas:
  "picture message"
}

.sender {
  grid-template-columns: 1fr 280px 50px;
  grid-template-areas:
  "spacer message picture";
}

.sender .messageContent {
  background: #4470b4;
  color: white;
}

.received .messageContent {
  background: #f0f0f0;
  color: black;
}


.messagePicture {
  grid-area: picture;
  width: 50px;
  height: 40px;

  color: white;

  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.messageContent {
  grid-area: message;
  color: black;
  border-radius: 5px;
  box-sizing: content-box;
  padding: 10px;
  white-space: pre;
}

.spacer {
  grid-area: spacer;
}


.TextAreaWrapper {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 10fr 2fr;
  grid-template-areas:
  "textarea sendBtn";
}

.textAreaContainer {
  grid-area: textarea;
}

.sendBtn {
  grid-area: sendBtn;
  height: 86px;
}

.loadMore {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  display: block;
}
