.positionWrapper {
  display: grid;
  grid-template: "position company";
  grid-template-columns: 1fr 300px;
}

.position {
  grid-area: position;
  padding: 10px;
}

.company {
  grid-area: company;
  padding:10px;
  background: #f0f0f0;
}

.ck-content {
  min-height: 200px;
}
