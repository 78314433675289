body {
  background-image: url("images/bg.png");
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

.card {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);

  /*background-color: #ffffffb3 !important;*/
}

.list-group-item:hover {
  background: #dbdbdb;
}

.navigation {
  width: 100%;
  color: white;
  height: 60px;
  padding: 10px;

  display: grid;
  grid-template-columns: 200px 4fr 2fr;
  grid-template-areas:
  "logo menu login"
}

.logo {
  grid-area: logo;
  font-size: 20px;
  line-height: 40px;
  color: white;
}

.logo a {
  color: white;
}

.logo a:hover {
  color: white;
  text-decoration: none;
}

.menu {
  grid-area: menu;
  line-height: 40px;
  text-align: center;
}

.menu a {
  display: inline-block;
  color: white;
  line-height: 40px;
  padding-right: 20px;
}

.menu a:hover {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}

.login {
  grid-area: login;
  line-height: 40px;
  text-align: right
}

.login a {
  display: inline-block;
  color: white;
  line-height: 30px;
  padding-right: 20px;
}

.login a:hover {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}

.searchBar {
  width: 100%;
  grid-area: search;
  height: 40px !important;
}

.searchPrediction {
  position: relative;
  z-index: 1000;
  background: white;
  color: black;
}

.hidden {
  display: none !important;
}


/* Listgroup */
.listGroupSegments {
  width: 100%;
  color: white;

  display: grid !important;
  grid-template-columns: 120px  5fr 1fr;
  grid-template-areas:
  "lgLogo lgContent lgAction"
}

.listGroupSegments .list-group-item {
  display: grid !important;
}

.lgLogo {
  grid-area: lgLogo;
  width: 100px;
  height: 40px;

  color: white;

  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.lgContent {
  grid-area: lgContent;
  color: black;
}

.lgAction {
  grid-area: lgAction;

  color: darkgrey;
  text-align: right;
}

.topContainer {
  display: grid;

  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  "left right"
}

.topContainer .left {
  grid-area: left;
}

.topContainer .right {
  grid-area: right;
}


@media screen and (min-width: 768px) {
  .container.full {
      width: 85% !important;
      left: 0 !important;
      right: 0!important;
      padding: 0px;
  }
}
@media (min-width: 1500px){
  .container.full {
      padding: 0 30px;
      width: 100%;
  }
}
@media (min-width: 1200px){
  .container {
      width: 1170px;
  }
}
@media (min-width: 992px){
  .container {
      width: 970px;
  }
}

@media (min-width: 768px){
  .container {
      width: 750px;
  }
}
