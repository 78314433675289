.slider {
  position: relative;
  height: 450px;
  width: 100vw;
}

.sliderBg {
  width: 100vw;
  height: 450px;
  background: url("https://images.pexels.com/photos/159751/book-address-book-learning-learn-159751.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500");
  background-size: cover;
  background-position: center center;
  position: absolute;
  z-index: 100;
}

.sliderOverlay {
  /*background: rgba(0, 64, 97, 0.6);*/
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 110;
  height: 450px;
  width: 100vw;
}

.sliderHeader {
  width: 100vw;
  z-index: 200;
  position: absolute;
  font-size: 60px;
  text-align: center;
  top: 50px;
  color: white;
}

.sliderText {
  width: 100vw;
  z-index: 200;
  position: absolute;
  font-size: 40px;
  text-align: center;
  top: 140px;
  color: white;
}

.sliderPop {
  color: #076fa3;
}

.searchWrapper {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 11fr 1fr;
  grid-template-areas:
  "search searchBtn";
}

.searchContainer {
  grid-area: search;
}

.searchBtn {
  grid-area: searchBtn;
}

.searchBtn Button {
  width: 100%
}
